<template>
	<!-- <div :class="shadow ? 'publicBox' : 'publicBox publicBoxNoshadow'"> -->
	<div class="publicBox publicBoxNoshadow">
		<!--导航-->
		<div class="header-box neirongbox">
			<div class="header-box-2">
				<a class="logo-box" href="/"><img src="../assets/imgs/logo@2x.png"></a>
				<ul class="header-nav">
					<li class="nav_active" @click="toPath('/')">首页</li>
					<li @click="toPath('/introduction')">字库介绍</li>
					<li @click="toPath('/certificate')">版权证书</li>
					<li @click="toPath('/purchaseNotes')">购买须知</li>
					<li @click="toPath('/aboutWebsites')">关于网站</li>
					<li @click="toPath('/aboutLbk')">关于刘兵克</li>
				</ul>
			</div>
			<div class="login" v-if="loginIs == true">已登录</div>
			<div class="login" v-else @click="toLogin()">登录/注册</div>
		</div>


		<!-- <div class="spvip" @click="dailyFun('vip')">视频会员</div> -->

		<div style="margin-top: 69px; min-height: 740px" id="test">
			<keep-alive
				exclude="dailyWordDetail,materialDetail,videoDetail,rankingDetail,projectDetail,fontSearchDetail,user">
				<router-view ref="nextRef"></router-view>
			</keep-alive>
		</div>
	</div>
</template>

<script>
	export default {
		name: "public",
		data() {
			return {
				isNav: false,
				theme_list: [], //导航分类
				loginIs: false //是否已登录

			};
		},
		mounted() {
			// 获取当前URL中的查询参数
			const queryParams = new URLSearchParams(window.location.search);

			// 获取access_token参数的值
			const accessToken = queryParams.get('access_token');

			// 判断access_token参数是否为空
			if (accessToken == 'null' || accessToken == '' || accessToken == null) {

				console.log('access_token 参数为空');
				this.loginIs = false

			} else {
				console.log('access_token 参数不为空，其值为：', accessToken);
				this.loginIs = true
				localStorage.setItem("myToken",accessToken); //存缓存
				
			}
			
			// if(localStorage.getItem("myToken") !== 'null' || localStorage.getItem("myToken") !== '' || localStorage.getItem("myToken") !== null ){
			// 	this.loginIs = true
			// }

			// window.loginShow = this.loginShow;
			// if (localStorage.getItem("userInfo")) {
			// 	this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
			// } else {
			// 	this.readNo = 0;
			// }
			// console.log("userInfo", this.userInfo);

			this.$nextTick(() => {
				var browerWidth = window.innerWidth; //浏览器可视宽度
				if (1180 < browerWidth < 1565) {
					var baseWidth = 1565; //设计稿宽度
					var zoomValue = browerWidth / baseWidth; //缩放比例计算
					console.log(browerWidth);

					if (zoomValue < 1) {
						document.getElementById("test").style.zoom = zoomValue.toFixed(2);
					}

				}
			});
		},
		methods: {
			//去登录
			toLogin() {
				// this.$router.push({
				// 	path: 'https://www.zitisheji.com/#/index'
				// })
				window.open('https://www.zitisheji.com/#/index');
			},


			// 导航跳转
			toPath(path) {
				this.$router.push({
					path: path
				})
			},

			//跳转页面
			dailyFun(e) {
				localStorage.setItem("is_benren", 1);
				localStorage.removeItem("userInfo_other");
				if (e == "user" && !localStorage.getItem("access_token")) {
					this.isLogin = true;
					return false;
				}
				localStorage.setItem("userId", this.userInfo.id);
				let routeData = null;
				if (e == "vip") {
					routeData = this.$router.resolve({
						name: "refresh",
						query: {
							usernext: e,
						},
					});
				} else {
					routeData = this.$router.resolve({
						name: e,
						query: {
							userId: this.userInfo.id,
						},
					});
				}
				window.open(routeData.href, "_blank");
			},

			// 退出登录
			quitFun() {
				localStorage.removeItem("access_token");
				localStorage.removeItem("userInfo");
				this.$store.commit("userInfo", "");
				this.userShow = false;
				this.$router.push({
					name: "/",
				});
			},

			// 全局登录方法
			loginShow() {
				this.isLogin = true;
			},
			// 注册
			registerFun() {
				this.isLogin = true;
				this.$nextTick(() => {
					this.$refs.loginType.loginType = 4;
				});
			},

			// 协议
			seeShare(id) {
				let routeData = this.$router.resolve({
					name: "userAgreement",
					query: {
						id: id,
					},
				});
				window.open(routeData.href, "_blank");
			},

			refreshFun(e) {
				localStorage.setItem("clearAll", 1);

				this.$router.push({
					name: "refresh",
					query: {
						usernext: e,
					},
				});
			},

		},
		watch: {
			"$store.state.navList"(newval) {
				this.theme_list = newval.theme_list;
				this.other_list = newval.other_list;
				this.hot_words = newval.hot_words;
				this.videoNav_list = newval.video_list;

				if (this.hot_words.length > 0) {
					this.search_name = this.hot_words[0].name;
				}
				this.webinfo = newval.webinfo;
			},
			showIndex(newval) {
				console.log("newval", newval);
				if (newval == 1) {
					this.showList = false;
				} else {
					this.showList = true;
				}
			},
			userInfo() {
				if (!localStorage.getItem("access_token")) {
					this.userShow = false;
				} else {
					this.userShow = true;
				}
				this.$forceUpdate;
			},
			"$store.state.userInfo"(newval) {
				console.log("监听监听");
				this.userInfo = newval;
				this.readNo = 0;
			},

		},
	};
</script>